<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BookingTypeBadge from '../../../../components/BookingTypeBadge/BookingTypeBadge';
import { BOOKING_TYPES } from '../../../../const/bookingTypes';
import CirclesFeedbackComponent from './CirclesFeedbackComponent';

export default {
  components: {
    CirclesFeedbackComponent,
    BookingTypeBadge,
    PermissionLink,
  },
  props: {
    showUserLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    bookingType() {
      return get(this, 'booking.isUnavailability') ? BOOKING_TYPES.unavailability : get(this, 'booking.type');
    },
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      booking: state => state.booking.data,
    }),
  },
  created() {
    this.sentenceCase = sentenceCase;
    this.CRM_PERMISSIONS = CRM_PERMISSIONS;
    this.crm = crm;
  },
  methods: {
    get,
  },
};
</script>
<template>
  <div
    v-if="booking"
    class="BookingInformation row"
    data-test-id="booking_information"
  >
    <div
      v-if="showUserLink"
      class="col-12 mb-4"
    >
      <ui-label label="User" />
      <PermissionLink
        :link-permissions="[CRM_PERMISSIONS.viewCrmUsers]"
        :to="{
          name: crm.users.detail.bookings,
          params: {
            userUuid: get(booking, 'user.uuid'),
          },
        }"
        target="_blank"
        class="emobg-caption-1"
        data-test-id="crm_users-link"
      >
        {{ get(booking, 'user.name', FALLBACK_MESSAGE.dash) }}
      </PermissionLink>
      <CirclesFeedbackComponent />
    </div>
    <div class="col-md-6 mb-4">
      <span class="d-block emobg-caption-2 pb-2 emobg-color-ink">
        Booking type
      </span>
      <BookingTypeBadge
        :type="bookingType"
        :is-automatic="get(booking, 'isAutomatic', false)"
        data-test-id="booking_type-badge"
      />
    </div>
    <div class="col-md-6 mb-4">
      <span class="d-block emobg-caption-2 pb-2 emobg-color-ink">
        System status
      </span>
      <span class="px-2 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill">
        <i class="d-inline-block icons-system-status align-middle" />
        {{ sentenceCase(booking.systemStatus) || FALLBACK_MESSAGE.notAvailable }}
      </span>
    </div>
    <div class="col-md-6 mb-4">
      <span class="d-block emobg-caption-2 pb-2 emobg-color-ink">
        Operator
      </span>
      <span>
        {{ get(booking, 'operator.name', FALLBACK_MESSAGE.dash) }}
      </span>
    </div>
    <div class="col-md-6 mb-4">
      <span class="d-block emobg-caption-2 pb-2 emobg-color-ink">
        Usage status
      </span>
      <span class="px-2 emobg-background-color-ground-lighter emobg-border-1 emobg-border-color-ground emobg-border-radius-pill">
        <span class="d-inline-block icons-vehicle-status align-middle" />
        <span v-if="booking.isUnavailability">
          {{ FALLBACK_MESSAGE.notAvailable }}
        </span>
        <span v-else> {{ sentenceCase(booking.vehicleStatus) || FALLBACK_MESSAGE.notAvailable }} </span>
      </span>
    </div>
    <div
      v-if="!booking.isUnavailability"
      class="col-md-6 mb-4"
    >
      <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
        Profile
      </span>
      <span>
        {{ sentenceCase(get(booking, 'userProfile.type')) }}
      </span>
    </div>

    <div
      v-if="get(booking, 'preBookingUuid')"
      class="col-md-6 mb-4"
    >
      <span class="d-block emobg-font-small pb-2 emobg-font-weight-bold emobg-color-ink">
        Pre-booking UUID
      </span>
      <span>
        #{{ get(booking, 'preBookingUuid') }}
      </span>
    </div>
  </div>
</template>
