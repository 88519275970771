<script>
import { getValue } from '@emobg/web-utils';
import { mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getMessageText } from '@/components/GlobalMessaging/GlobalMessagingComponent';
import { EXCEPTION_KEYS } from './const/exceptionKeys.const';

export default {
  props: {
    userUuid: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      bookingError: state => getValue(state, 'booking.error', null),
      vehicleLocationsError: state => getValue(state, 'locations.error', null),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      editBookingError: state => getValue(state, 'error', null),
      getEditBookingError: state => getValue(state, 'booking.error', null),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    error() {
      return this.identityCardError
        || this.drivingLicenseError
        || this.bookingError
        || this.editBookingError
        || this.vehicleLocationsError
        || this.getEditBookingError;
    },
    identityCardError() {
      const message = `User has not provided an ID. To continue, go to the
            <a href="/user-company/users/${this.userUuid}/account" target="_blank">user's page</a> to add their ID document details.`;
      return getValue(this, 'bookingError.key', '') === EXCEPTION_KEYS.identityCardRequired
        ? { ...this.bookingError, message }
        : null;
    },
    drivingLicenseError() {
      const errorKey = this.isEditing ? getValue(this, 'editBookingError.key', '') : getValue(this, 'bookingError.key', '');

      const messages = {
        [EXCEPTION_KEYS.drivingLicenseExpired]: 'Booking cannot be created, user\'s driving license has expired.',
        [EXCEPTION_KEYS.drivingLicenseNotValidForWhileBooking]: this.isEditing
          ? 'Booking cannot be updated as user’s driving license will be expired before the end of the booking.'
          : 'Booking cannot be created as user\'s driving license is not valid for the whole booking time.',
      };

      const message = messages[errorKey];
      return message ? { ...this.bookingError, message } : null;
    },
  },
  created() {
    this.getMessageText = getMessageText;
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.create, [
      'clearBookingError',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.edit, [
      'clearEditBookingError',
    ]),
    clearErrors() {
      this.clearBookingError();
      this.clearEditBookingError();
    },
  },
};
</script>
<template>
  <div
    v-if="error"
    class="w-100 position-relative"
    data-test-id="booking_actions_feedback"
  >
    <ui-alert
      :color="COLORS.danger"
      :dismissible="!getEditBookingError"
      :icon="ICONS.infoFull"
      class="animated fadeIn d-block pb-3 w-100"
      data-test-id="alert"
      @dismissAlert="clearErrors()"
    >
      <p v-html="getMessageText(error, $t, operatorTimezone)" />
    </ui-alert>
  </div>
</template>
