<script>
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import join from 'lodash/join';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import size from 'lodash/size';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiSelect,
  MuiTextarea,
  Validate,
} from '@emobg/motion-ui/v1';

import {
  camelCaseKeys,
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  DELAY,
  formatPreciseCurrency,
  reformatDateTime,
  TIME_ZONE,
  titleCase,
} from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { duration } from '@domains/Carsharing/utils/duration';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import {
  CancelButton,
  GenericModalComponent,
  PermissionLink,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { BOOKING_TYPES } from '../../../const/bookingTypes';
import { scopes } from '../../store/PlanningCreateModule';
import {
  BookingActionsComponent,
  BookingActionsFeedback,
  BookingCommentsComponent,
  BookingFeedback,
  BookingInformationComponent,
  BookingLocationOptionComponent,
  BookingVehicleOptionComponent,
  CirclesFeedbackComponent,
  DedicatedHoursFeedback,
  ForceFinish,
} from './components';
import CostAllocationsComponent
  from '../../../BookingSets/components/GeneralInformation/components/CostAllocations/CostAllocationsComponent';
import { timezoneCorrector } from '../../config';
import { MIN_COMMENT_CHARACTERS, STARTED_USAGE_STATUS } from '../../const';

export default {
  components: {
    BookingActionsComponent,
    BookingActionsFeedback,
    BookingCommentsComponent,
    BookingInformationComponent,
    BookingFeedback,
    BookingLocationOptionComponent,
    BookingVehicleOptionComponent,
    CirclesFeedbackComponent,
    CancelButton,
    CostAllocationsComponent,
    DedicatedHoursFeedback,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiSelect,
    MuiTextarea,
    PermissionLink,
    ForceFinish,
  },
  directives: {
    Validate,
  },
  props: {
    success: {
      type: Function,
      default: () => {},
    },
    initial: {
      type: Object,
      default: null,
    },
    bookingUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isPrebookingReady: true,
      isInitialized: false,
      startDate: '',
      endDate: '',
      userUuid: null,
      requestUuid: null,
      vehicleUuid: null,
      operatorUuid: null,
      userProfileUuid: null,
      insuranceUuid: null,
      cityUuid: null,
      locationUuid: null,
      returnLocationUuid: null,
      comment: '',
      companyCostAllocations: [],
      bookingType: null,
      interventionType: null,
      isAnotherReturnLocation: false,
      isCommentFieldDirty: false,
      isFreeBooking: false,
      skippedRules: {},
      isCostAllocationsValid: true,
      originalLocationUuid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.planning.create, {
      createBookingStatus: state => state.booking.STATUS,
      bookingsPrice: state => state.price.data,
      bookingTypes: state => state.types.data,
      bookingTypesStatus: state => state.types.STATUS,
      bookingError: state => get(state, 'booking.error'),
      brokenBookingRules: state => state.rules.data,
      operatorConfig: state => state.configs.data,
      userProfiles: state => state.profiles.data.profiles,
      circles: state => state.profiles.data.circles,
      locations: state => state.locations.data || [],
      locationsStatus: state => state.locations.STATUS,
      vehicles: state => state.vehicles.data || [],
      vehiclesStatus: state => state.vehicles.STATUS,
      dedicatedHours: state => state.dedicatedHours.data,
      dedicatedHoursStatus: state => state.dedicatedHours.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.planning.edit, {
      booking: state => state.booking.data,
      editBookingError: state => get(state, 'error'),
      getEditBookingError: state => get(state, 'booking.error'),
      editBookingStatus: state => state.STATUS,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
      activeOperatorUuid: state => state.operators.active.uuid,
      activeOperatorId: state => state.operators.active.id,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.cities, {
      citiesData: state => state.data,
      citiesStatus: state => state.STATUS,
    }),
    isChangingCloudBoxxToIBoxx() {
      const selectedVehicle = find(this.vehicles, { uuid: this.vehicleUuid });

      return get(this, 'booking.vehicle.isConnectedCarPlatform') && (selectedVehicle && !get(selectedVehicle, 'isConnectedCarPlatform'));
    },
    isButtonLoading() {
      return this.editBookingStatus.LOADING || this.createBookingStatus.LOADING;
    },
    minutesRange() {
      return this.bookingType === BOOKING_TYPES.unavailability ? 1 : 15;
    },
    validEndDateRange() {
      const minStart = this.startDate && this.startDate.isAfter(moment().startOf('minute')) ? this.startDate : moment().startOf('minute');

      return {
        start: minStart,
        end: moment().add(10, 'years'),
      };
    },
    isFormValid() {
      const isCostAllocationsSkippable = get(this, 'currentProfile.costAllocations.length') ? this.isCostAllocationsValid : true;
      return (this.startDate && this.startDate.isValid())
        && (this.endDate && this.endDate.isValid())
        && isCostAllocationsSkippable;
    },
    isNotBookable() {
      return get(this, 'dedicatedHours.cannotBook');
    },
    hasSkippedRules() {
      return size(pickBy(this.brokenBookingRules, identity));
    },
    hasStarted() {
      if (this.booking && this.booking.start && this.bookingType === BOOKING_TYPES.unavailability) {
        const now = reformatDateTime(moment.utc().format(DATE_FORMAT.filter), DATE_FORMAT.filter, this.operatorTimezone);
        const bookingStartCorrected = timezoneCorrector(this.booking.start, this.operatorTimezone, DATE_FORMAT.filter, DATE_FORMAT.filter);
        return moment(now).isAfter(bookingStartCorrected);
      }

      return STARTED_USAGE_STATUS.includes(get(this, 'booking.vehicleStatus'));
    },
    isEditing() {
      return !!this.bookingUuid;
    },
    isAbleToChangeUser() {
      return this.isEditing && get(this, 'booking.type') === BOOKING_TYPES.intervention && !this.hasStarted;
    },
    isCarsharing() {
      return [
        BOOKING_TYPES.carsharing,
        BOOKING_TYPES.longDistance,
      ].includes(this.bookingType);
    },
    hasPriceChanged() {
      return this.isEditing && size(get(this, 'booking.price', []))
        && size(this.bookingsPrice)
        && !isEqual(get(this, 'booking.price'), this.bookingsPrice)
        && !this.isFreeBooking;
    },
    insurances() {
      return get(this.currentProfile, 'insurances', []);
    },
    currentProfile() {
      return find(this.userProfiles, { uuid: this.userProfileUuid }) || {};
    },
    currentPrice() {
      if (this.isEditing && this.isFreeBooking) {
        return null;
      }

      return this.isEditing ? get(this, 'booking.price') : this.bookingsPrice;
    },
    isBookingTypeOneWayApplicable() {
      return [
        BOOKING_TYPES.carsharing,
        BOOKING_TYPES.intervention,
      ].includes(this.bookingType);
    },
    isBookingValid() {
      return (this.userProfileUuid
        && this.userUuid
        && this.locationUuid
        && this.cityUuid
        && this.operatorUuid
        && this.vehicleUuid
        && this.bookingType
        && this.comment.length >= 6
        && (this.insuranceUuid || this.interventionType)
        && this.isFormValid);
    },
    editTitle() {
      if (this.isInitialized) {
        return this.bookingType === BOOKING_TYPES.unavailability ? 'Edit unavailability' : 'Edit booking';
      }

      return '';
    },
    placeholder() {
      return this.isEditing ? 'Reason for editing the booking' : 'Reason for creating the booking';
    },
    isVehicleDisabled() {
      return !this.locationUuid || !this.operatorUuid || this.hasStarted || (this.isEditing && !this.isCarsharing);
    },
    isOneWayAllowed() {
      const currentLocation = find(this.locations, { uuid: this.locationUuid });

      return get(currentLocation, 'oneWayAllowed')
        && this.isBookingTypeOneWayApplicable;
    },
    isOneWayVehicle() {
      const currentVehicle = find(this.vehicles, { uuid: this.vehicleUuid });

      return get(currentVehicle, 'oneWayAllowed');
    },
  },
  watch: {
    async bookingType(newBookingType, oldBookingType) {
      if (oldBookingType && newBookingType !== oldBookingType) {
        await this.updateUserInformation(this.userUuid, newBookingType);
      }
    },
    async operatorUuid(newOperatorUuid) {
      if (newOperatorUuid) {
        this.getOperatorConfigs(newOperatorUuid);
        await this.getBookingTypes({ cs_operator_uuid: newOperatorUuid, user_profile_uuid: this.userProfileUuid });
      }
    },
    async userProfileUuid(newProfileUuid) {
      if (this.operatorUuid && newProfileUuid) {
        await this.getBookingTypes({ cs_operator_uuid: this.operatorUuid, user_profile_uuid: newProfileUuid });
      }
    },
    startDate(newDate, oldDate) {
      const startDate = moment(newDate);

      if (oldDate && newDate && (oldDate.isSameOrBefore(newDate) && !this.endDate)) {
        this.endDate = this.minuteRangeCorrector(startDate.add(1, 'minute'));
      }

      const endDate = moment(this.endDate);
      if (this.endDate && endDate.isSameOrBefore(startDate)) {
        this.endDate = this.minuteRangeCorrector(startDate.add(1, 'minute'));
      }
    },
    async locationUuid(newLocation, oldLocation) {
      if (newLocation && newLocation !== oldLocation && !this.isEditing) {
        this.originalLocationUuid = newLocation;
        await this.fetchLocationsIfPossible();
      }
      if (newLocation !== get(this.initial, 'locationUuid')) {
        this.vehicleUuid = null;
      }
    },
    isOneWayAllowed(value) {
      this.updateAnotherLocationByOneWay(value);
    },
    isOneWayVehicle(value) {
      this.updateAnotherLocationByOneWay(value);
    },
  },
  async created() {
    this.resetState({
      scopes: [
        scopes.bookingRules,
        scopes.bookingUserProfiles,
        scopes.bookingPrice,
        scopes.booking,
        scopes.locations,
        scopes.vehicles,
        scopes.dedicatedHours,
      ],
    });
    this.resetBookingData();
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.MIN_COMMENT_CHARACTERS = MIN_COMMENT_CHARACTERS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.BOOKING_TYPES = BOOKING_TYPES;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.carsharingRoutes = carsharingRoutes;
    this.duration = duration;
    this.validDateRange = {
      start: moment(),
      end: moment().add(10, 'years'),
    };

    if (this.initial) {
      this.operatorUuid = get(this, 'initial.operatorUuid', this.operatorUuid);
      this.cityUuid = this.initial.cityUuid;
      this.locationUuid = this.initial.locationUuid;
      this.returnLocationUuid = this.initial.returnLocationUuid;
      this.vehicleUuid = this.initial.vehicleUuid;
      this.bookingType = get(this, 'initial.bookingType', BOOKING_TYPES.carsharing);
      this.originalLocationUuid = get(this.initial, 'locationUuid', null);
      this.onChangeUserUuid(get(this, 'initial.userUuid', null));
    } else {
      this.operatorUuid = this.activeOperatorUuid;
      this.bookingType = BOOKING_TYPES.carsharing;
    }

    if (this.bookingUuid) {
      await this.getEditBooking(this.bookingUuid);
      this.operatorUuid = get(this, 'booking.operator.uuid');
      this.isAnotherReturnLocation = get(this, 'booking.isOneWay');
      this.isFreeBooking = get(this, 'booking.isFree');
      this.userProfileUuid = get(this, 'booking.userProfile.uuid');
      this.insuranceUuid = get(this, 'booking.insurance.uuid');
      this.interventionType = get(this, 'booking.interventionType');
    }

    const debounceFetchLocations = debounce(
      this.fetchLocationsIfPossible,
      DELAY.medium,
    );
    this.$watch(vm => [
      vm.startDate,
      vm.endDate,
      vm.operatorUuid,
      vm.cityUuid,
      vm.bookingType,
      vm.isAnotherReturnLocation,
    ], async () => {
      await debounceFetchLocations();
    });

    const debounceFetchVehicles = debounce(
      this.fetchVehicles,
      DELAY.medium,
    );
    this.$watch(vm => [
      vm.startDate,
      vm.endDate,
      vm.operatorUuid,
      vm.locationUuid,
      vm.bookingType,
      vm.isAnotherReturnLocation,
    ], async () => {
      await debounceFetchVehicles();
    });

    const debounceFetchBookingPrice = debounce(async () => {
      const isInterventionWithPersonal = this.bookingType === BOOKING_TYPES.intervention && this.currentProfile.type === 'personal';
      const canGetBookingRules = this.userUuid
        && this.operatorUuid && this.userProfileUuid
        && this.vehicleUuid && this.bookingType
        && this.bookingType !== BOOKING_TYPES.unavailability
        && (this.startDate && this.startDate.isValid()) && (this.endDate && this.endDate.isValid())
        && !isInterventionWithPersonal;

      if (canGetBookingRules) {
        await this.getBookingRules({
          userUuid: this.userUuid,
          operatorUuid: this.operatorUuid,
          userProfileUuid: this.userProfileUuid,
          vehicleUuid: this.vehicleUuid,
          bookingType: this.bookingType,
          bookingUuid: this.isEditing ? this.bookingUuid : null,
          start: this.dateForRequest(this.startDate),
          end: this.dateForRequest(this.endDate),
        });
      }

      const canGetBookingPrice = this.insuranceUuid
        && this.operatorUuid && this.userProfileUuid
        && this.vehicleUuid && this.bookingType
        && (this.startDate && this.startDate.isValid()) && (this.endDate && this.endDate.isValid())
        && this.isCarsharing;

      if (canGetBookingPrice) {
        await this.postBookingsPrice({
          insuranceUuid: this.insuranceUuid,
          vehicleUuid: this.vehicleUuid,
          bookingType: this.bookingType,
          start: this.dateForRequest(this.startDate),
          end: this.dateForRequest(this.endDate),
          userProfileUuid: this.userProfileUuid,
          csOperatorUuid: this.operatorUuid,
          is_free: this.isFreeBooking,
          bookingUuid: this.isEditing ? this.bookingUuid : null,
        });
      }
    }, DELAY.medium);

    this.$watch(vm => [
      vm.userUuid,
      vm.cityUuid,
      vm.operatorUuid,
      vm.userProfileUuid,
      vm.vehicleUuid,
      vm.insuranceUuid,
      vm.bookingType,
      vm.locationUuid,
      vm.startDate,
      vm.endDate,
      vm.isFreeBooking,
    ], async () => {
      await debounceFetchBookingPrice();
    });

    const debounceFetchDedicatedHours = debounce(this.fetchDedicatedHours, DELAY.medium);
    this.$watch(vm => [
      vm.operatorUuid,
      vm.userUuid,
      vm.userProfileUuid,
      vm.locationUuid,
      vm.vehicleUuid,
      vm.bookingType,
      vm.startDate,
      vm.endDate,
    ], async () => {
      await debounceFetchDedicatedHours();
    });

    this.isInitialized = true;

    if (this.isEditing) {
      this.startDate = moment(timezoneCorrector(get(this, 'booking.start'), this.operatorTimezone));
      this.endDate = moment(timezoneCorrector(get(this, 'booking.end'), this.operatorTimezone));
    } else if (this.initial) {
      this.startDate = moment(this.initial.start);
      this.endDate = moment(this.initial.end);
    }
    this.normalizeOptions = (collection, value, label) => map(collection, item => ({
      value: value(item),
      label: label(item),
    }));

    this.saveActions = [
      {
        label: 'Apply new price',
        action: () => this.editBooking(true),
      },
      {
        label: 'Keep current price',
        action: () => this.editBooking(false),
      },
    ];
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.planning.create, [
      'postBookingsPrice',
      'getBookingTypes',
      'getBookingUserProfiles',
      'getBookingRules',
      'getOperatorConfigs',
      'postBookings',
      'getLocations',
      'getVehiclesByLocation',
      'getDedicatedHours',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.planning.edit, [
      'getEditBooking',
      'putEditBooking',
      'putEditUnavailability',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.create, [
      'resetState',
      'setData',
      'clearBookingError',
      'clearProfiles',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.planning.edit, [
      'resetBookingData',
      'clearEditBookingError',
    ]),
    get,
    isEmpty,
    omit,
    size,
    reformatDateTime,
    titleCase,
    formatPreciseCurrency,
    scrollToTop() {
      set(this, '$refs.bookingSetupModal.$el.scrollTop', 0);
    },
    updateAnotherLocationByOneWay(isOneWay) {
      if (!isOneWay && !get(this, 'booking.isOneWay')) {
        this.isAnotherReturnLocation = false;
      }
    },
    async onChangeUserUuid(newUserUuid) {
      if (newUserUuid !== this.userUuid) {
        this.userUuid = newUserUuid;
        this.companyCostAllocations = [];
        await this.updateUserInformation(newUserUuid, this.bookingType);
        await this.fetchLocationsIfPossible();
        await this.fetchVehicles();
      }
    },
    minuteRangeCorrector(date) {
      const dateToReturn = cloneDeep(date);
      if (this.minutesRange) {
        const module = dateToReturn.minute() % this.minutesRange;
        if (module > 0) {
          return dateToReturn.add(this.minutesRange - module, 'minutes');
        }
      }

      return dateToReturn;
    },
    validateStartDate(newDate) {
      this.startDate = newDate.isValid()
        ? newDate
        : this.minuteRangeCorrector(moment().startOf('minute'));
    },
    validateEndDate(newDate) {
      const endDate = newDate && newDate.isValid() ? moment(newDate) : moment();
      const bookingEndDate = this.isEditing ? moment(timezoneCorrector(get(this, 'booking.end'), this.operatorTimezone)) : null;
      const isBookingEndDateInPast = bookingEndDate && bookingEndDate.isBefore(moment().startOf('minute'));
      const isEndBeforeStart = bookingEndDate && bookingEndDate.isBefore(this.startDate);
      const minEndDate = (isBookingEndDateInPast && !isEndBeforeStart) ? bookingEndDate : get(this, 'validEndDateRange.start');

      this.$nextTick(() => {
        if (endDate && minEndDate && endDate.isSameOrBefore(minEndDate)) {
          this.endDate = (isBookingEndDateInPast && !isEndBeforeStart)
            ? minEndDate
            : this.minuteRangeCorrector(moment(minEndDate).add(1, 'minute'));
        } else {
          this.endDate = newDate;
        }
      });
    },
    getSkippedRules() {
      const skippedRules = pickBy(this.brokenBookingRules, identity);
      const mappedRulesKeys = mapKeys(skippedRules, (_value, key) => this.$t(`Carsharing.Planning.BookingRules.${key}`));

      return join(keys(mappedRulesKeys), '\n');
    },
    interventionTitle(result) {
      const value = result.name_translations[`${this.userLocale}`];
      return isEmpty(value) ? result.name_translations.en_GB : value;
    },
    async updateUserInformation(userUuid, bookingType) {
      if (userUuid && bookingType !== BOOKING_TYPES.unavailability) {
        this.clearProfiles();
        await this.getBookingUserProfiles({ userUuid, bookingType });
        const businessProfile = findIndex(this.userProfiles, { type: 'business' });
        const fallbackIndex = businessProfile > -1 ? businessProfile : 0;
        let currentProfileIndex = findIndex(this.userProfiles, { uuid: this.userProfileUuid });
        currentProfileIndex = currentProfileIndex > -1 ? currentProfileIndex : fallbackIndex;
        this.userProfileUuid = get(this.userProfiles, `[${currentProfileIndex}].uuid`, null);

        let currentInsuranceIndex = findIndex(this.insurances, { uuid: this.insuranceUuid });
        currentInsuranceIndex = currentInsuranceIndex > -1 ? currentInsuranceIndex : 0;
        this.insuranceUuid = get(this.insurances, `[${currentInsuranceIndex}].uuid`, null);
      }
    },
    dateForRequest(momentDate) {
      const momentTimezoned = moment.tz(momentDate && momentDate.format(DATE_FORMAT.filter), this.operatorTimezone);
      return momentTimezoned.utc().format(DATE_FORMAT.filter);
    },
    async editUnavailability() {
      const data = {
        vehicle_uuid: this.vehicleUuid,
        start: this.startDate.format(DATE_FORMAT.defaultExtended),
        end: this.endDate.format(DATE_FORMAT.defaultExtended),
        comment: this.comment,
      };
      await this.putEditUnavailability({
        unavailabilityUuid: this.bookingUuid,
        data: this.hasStarted ? omit(data, ['start']) : data,
      });

      if (this.editBookingStatus.LOADED) {
        this.onSuccessCallback();
      } else {
        this.scrollToTop();
      }
    },
    async editBooking(updatePrice = false) {
      const data = {
        userProfileUuid: this.userProfileUuid,
        locationUuid: this.locationUuid,
        destinationLocationUuid: this.isBookingTypeOneWayApplicable && this.isAnotherReturnLocation ? this.returnLocationUuid : null,
        vehicleUuid: this.vehicleUuid,
        insuranceUuid: this.insuranceUuid,
        start: this.dateForRequest(this.startDate),
        end: this.dateForRequest(this.endDate),
        comment: this.comment,
        interventionType: this.interventionType,
        updatePrice,
      };

      this.clearErrors();
      await this.putEditBooking({
        bookingUuid: this.bookingUuid,
        data,
      });

      if (this.editBookingStatus.LOADED && !this.editBookingError) {
        this.onSuccessCallback();
      } else {
        this.scrollToTop();
      }
    },
    editAction() {
      const action = this.bookingType === BOOKING_TYPES.unavailability ? this.editUnavailability : this.editBooking;
      action();
    },
    async createBooking() {
      const booking = {
        user_profile_uuid: this.userProfileUuid,
        location_uuid: this.locationUuid,
        cs_operator_uuid: this.operatorUuid,
        destination_location_uuid: this.isBookingTypeOneWayApplicable && this.isAnotherReturnLocation ? this.returnLocationUuid : null,
        vehicle_uuid: this.vehicleUuid,
        insurance_uuid: this.insuranceUuid,
        booking_type: this.bookingType,
        start: this.dateForRequest(this.startDate),
        end: this.dateForRequest(this.endDate),
        is_free: this.isFreeBooking,
        comment: this.comment,
        intervention_type: this.interventionType,
        pre_booking_uuid: this.requestUuid,
        company_cost_allocations: get(this, 'currentProfile.costAllocations.length') ? this.companyCostAllocations : [],
      };
      this.clearErrors();
      await this.postBookings(booking);

      if (this.createBookingStatus.LOADED && !this.bookingError) {
        this.onSuccessCallback();
      } else {
        this.scrollToTop();
      }
    },
    onSuccessCallback() {
      this.closeModal();
      this.success();
    },
    clearErrors() {
      this.clearBookingError();
      this.clearEditBookingError();
    },
    closeModal() {
      this.clearErrors();
      this.$emit('closeModal');
    },
    fillBookingWithPrebooking(prebooking) {
      if (prebooking) {
        const prebookingCamelCased = camelCaseKeys(prebooking);
        this.isPrebookingReady = false;
        this.$nextTick(() => {
          this.onChangeUserUuid(get(prebookingCamelCased, 'user.uuid', null));
          this.bookingType = get(prebookingCamelCased, 'csBookingUseCase.bookingType', null);
          this.startDate = moment(timezoneCorrector(get(prebookingCamelCased, 'startUtc'), this.operatorTimezone));
          this.endDate = moment(timezoneCorrector(get(prebookingCamelCased, 'endUtc'), this.operatorTimezone));
          this.cityUuid = get(prebookingCamelCased, 'location.city.id', null);
          this.isAnotherReturnLocation = get(prebookingCamelCased, 'csBookingUseCase.tripType') === 'one_way';
          this.locationUuid = get(prebookingCamelCased, 'location.uuid', null);
          this.returnLocationUuid = get(prebookingCamelCased, 'destinationLocation.uuid', null);
          this.companyCostAllocations = get(prebookingCamelCased, 'companyCostAllocations', []);
          this.isPrebookingReady = true;
        });
      }
    },
    async fetchDedicatedHours() {
      const canGetDedicatedHours = (this.startDate && this.startDate.isValid())
        && (this.endDate && this.endDate.isValid())
        && this.vehicleUuid
        && this.bookingType
        && this.userProfileUuid
        && this.operatorUuid;

      if (canGetDedicatedHours) {
        await this.getDedicatedHours({
          start: this.dateForRequest(this.startDate),
          end: this.dateForRequest(this.endDate),
          bookingType: this.bookingType,
          operatorUuid: this.operatorUuid,
          vehicleUuid: this.vehicleUuid,
          userProfileUuid: this.userProfileUuid,
        });
      }
    },
    async fetchVehicles() {
      const canGetVehicles = (this.startDate && this.startDate.isValid())
        && (this.endDate && this.endDate.isValid())
        && this.locationUuid
        && this.operatorUuid
        && this.bookingType;

      if (canGetVehicles) {
        await this.getVehiclesByLocation({
          locationUuid: this.locationUuid,
          params: {
            startDate: this.dateForRequest(this.startDate),
            endDate: this.dateForRequest(this.endDate),
            bookingType: this.bookingType,
            csOperatorUuid: this.operatorUuid,
            vehicleUuid: get(this, 'booking.vehicle.uuid'),
            userUuid: get(this, 'userUuid'),
            oneWayOnly: get(this, 'isAnotherReturnLocation'),
          },
        });

        const vehicle = find(this.vehicles, { uuid: this.vehicleUuid });
        this.vehicleUuid = vehicle ? this.vehicleUuid : null;
      }
    },
    async fetchLocationsIfPossible() {
      const canGetLocations = (this.startDate && this.startDate.isValid())
        && (this.endDate && this.endDate.isValid())
        && this.operatorUuid
        && this.cityUuid
        && this.bookingType;

      if (canGetLocations) {
        await this.getLocations({
          startDate: this.dateForRequest(this.startDate),
          endDate: this.dateForRequest(this.endDate),
          csOperatorUuid: this.operatorUuid,
          cityUuid: this.cityUuid,
          bookingType: this.bookingType,
          currentLocationUuid: this.originalLocationUuid,
          vehicleUuid: get(this, 'booking.vehicle.uuid'),
          userUuid: get(this, 'userUuid'),
          oneWayOnly: get(this, 'isAnotherReturnLocation'),
        });

        const location = find(this.locations, { uuid: this.locationUuid });
        this.locationUuid = location ? this.locationUuid : null;

        if (!this.locationUuid) {
          this.vehicleUuid = null;
          this.setData({ scope: scopes.vehicles, value: [] });
        }
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    ref="bookingSetupModal"
    :title="bookingUuid ? editTitle : 'Create new booking'"
    :header="{ isClosable: true }"
    is-alert-sticky
    data-test-id="booking-form-modal"
    @close-modal="closeModal"
    v-on="$listeners"
  >
    <div slot="alerts">
      <BookingActionsFeedback
        v-if="isEditing && isInitialized"
        data-test-id="booking-form-modal-actions-feedback"
      />
      <BookingFeedback :user-uuid="userUuid" />
      <DedicatedHoursFeedback />
    </div>
    <div slot="body">
      <div>
        <ui-loader
          v-if="!isInitialized"
          relative
          data-test-id="loader"
        />
        <div
          v-else-if="getEditBookingError"
          data-test-id="booking-form-modal-errors"
        >
          We could not get this booking, see error above
        </div>
        <div
          v-else
          class="BookingSetup row"
        >
          <div
            v-if="booking"
            class="col-12 emobg-body-2 mb-4 mt-3"
          >
            <span class="emobg-color-ink">
              Booking
            </span>
            <PermissionLink
              :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
              :to="{
                name: carsharingRoutes.bookings.detail.index,
                params: {
                  bookingUuid: get(booking, 'uuid'),
                },
              }"
              data-test-id="booking-form-modal-booking-link"
            >
              #{{ get(booking, 'id') || FALLBACK_MESSAGE.dash }}
            </PermissionLink>
          </div>
          <div
            v-if="isAbleToChangeUser"
            class="col-12 mb-4"
          >
            <label :class="['d-block emobg-font-weight-bold mb-1', {'disabled': !operatorUuid }]">
              User*
            </label>
            <MuiAlgoliaSelect
              :model="userUuid"
              :disabled="!operatorUuid"
              :index="ALGOLIA_INDEXES.users"
              :filters="`cs_operator_uuid: ${operatorUuid} OR employee.company.cs_operator_uuid: ${operatorUuid}`"
              :title="result => `${result.first_name} ${result.last_name} - ${result.email}`"
              data-test-id="booking-form-user"
              name="user"
              no-cache
              path-value="uuid"
              placeholder="Select"
              class="w-100"
              @change="onChangeUserUuid"
            />
            <CirclesFeedbackComponent />
            <div
              v-if="userUuid && isEmpty(currentProfile)"
              class="my-2"
            >
              <label class="emobg-font-weight emobg-font-extra-small emobg-color-danger">
                <ui-icon
                  :icon="ICONS.info"
                  :size="ICONS_SIZES.small"
                  data-test-id="booking-form-user-no-business-profile"
                />
                This user doesn't have business profile
              </label>
            </div>
          </div>
          <div class="col-12">
            <BookingInformationComponent
              v-if="bookingUuid"
              :show-user-link="!isAbleToChangeUser"
            />
          </div>
          <div
            v-if="!isEditing"
            class="w-100"
          >
            <div class="col-12 mb-4">
              <label class="d-block emobg-font-weight-bold mb-1">
                Operator*
              </label>
              <MuiAlgoliaSelect
                v-model="operatorUuid"
                :index="ALGOLIA_INDEXES.csOperators"
                :title="result => result.name"
                :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
                data-test-id="booking-form-operator"
                name="operator"
                no-cache
                path-value="uuid"
                placeholder="Select"
                class="w-100"
              />
            </div>
            <div
              v-if="operatorConfig.usesPreBooking"
              class="col-12 mb-4"
            >
              <label class="d-block emobg-font-weight-bold mb-1">
                Pre-booking UUID
                <span class="emobg-color-ink-light emobg-line-height-medium">(optional)</span>
              </label>
              <MuiAlgoliaSelect
                v-model="requestUuid"
                :disabled="!operatorUuid"
                :index="ALGOLIA_INDEXES.prebookings"
                :filters="`cs_operator_uuid: ${operatorUuid} AND booking_uuid: null`"
                :title="result => `${result.uuid}`"
                data-test-id="booking-form-request-uuid"
                name="requestUuid"
                no-cache
                path-value="uuid"
                placeholder="Select"
                class="w-100"
                @selected="fillBookingWithPrebooking"
              />
            </div>
            <div class="col-12 mb-4">
              <label :class="['d-block emobg-font-weight-bold mb-1', {'disabled': !operatorUuid }]">
                User*
              </label>
              <MuiAlgoliaSelect
                v-if="isPrebookingReady"
                :model="userUuid"
                :disabled="!operatorUuid"
                :index="ALGOLIA_INDEXES.users"
                :filters="`cs_operator_uuid: ${operatorUuid} OR employee.company.cs_operator_uuid: ${operatorUuid}`"
                :title="result => `${result.first_name} ${result.last_name} - ${result.email}`"
                data-test-id="booking-form-user-prebooking"
                name="user"
                no-cache
                path-value="uuid"
                placeholder="Select"
                class="w-100"
                @change="onChangeUserUuid"
              />
              <CirclesFeedbackComponent data-test-id="booking_form-circles_feedback" />
            </div>
            <div class="col-12 mb-4">
              <ui-select
                :value="userProfileUuid"
                :options.prop="normalizeOptions(userProfiles, value => value.uuid, label => titleCase(label.type))"
                :disabled="!userUuid"
                required
                label="Profile*"
                placeholder="Select"
                data-test-id="booking-form-profile"
                name="profile"
                class="w-100"
                @selectoption="({ detail }) => userProfileUuid = detail"
              />
            </div>
            <div class="col-12 mb-4">
              <ui-skeleton
                v-if="bookingTypesStatus.LOADING"
                data-test-id="booking_type-skeleton"
              />
              <ui-select
                v-else
                :value="bookingType"
                :options.prop="normalizeOptions(bookingTypes, value => value, label => titleCase(label))"
                label="Booking type*"
                placeholder="Select"
                class="w-100"
                required
                data-test-id="booking-form-type"
                name="booking_type"
                @selectoption="({ detail }) => bookingType = detail"
              />
            </div>
          </div>

          <div
            v-if="isCarsharing"
            class="col-12 mb-4"
          >
            <ui-select
              :value="insuranceUuid"
              :disabled="hasStarted || !userProfileUuid"
              placeholder="Select"
              label="Insurance type*"
              class="w-100"
              :options.prop="normalizeOptions(insurances, value => value.uuid, label => label.name)"
              required
              data-test-id="booking-form-insurance"
              name="insurance_type"
              @selectoption="({ detail }) => insuranceUuid = detail"
            />
          </div>
          <div
            v-if="bookingType === BOOKING_TYPES.intervention"
            class="col-12 mb-4"
          >
            <label :class="['d-block emobg-font-weight-bold mb-1', {'disabled': hasStarted || get(booking, 'isAutomatic')}]">
              Intervention type*
            </label>
            <MuiAlgoliaSelect
              v-model="interventionType"
              :disabled="hasStarted || get(booking, 'isAutomatic')"
              :index="ALGOLIA_INDEXES.carsharingTags"
              :results-per-page="50"
              :title="interventionTitle"
              class="m-0 w-100 pb-2"
              filters="group:interventions"
              data-test-id="booking-form-intervention"
              name="intervention"
              path-value="internal_name"
              placeholder="Select"
            />
          </div>
          <div class="col-sm-6 mb-1">
            <ui-datetimepicker
              :date.prop="startDate"
              :date-format-key="DATE_FORMAT_KEYS.default"
              :range.prop="validDateRange"
              :disabled="hasStarted"
              :minutes-interval.prop="bookingType === BOOKING_TYPES.unavailability ? 1 : minutesRange"
              :size="SIZES.small"
              :disable-date-coercion="isEditing"
              immediate
              label="Start date*"
              required
              data-test-id="booking-form-start-date"
              name="start"
              @datechanged="({ detail }) => validateStartDate(detail)"
            />
          </div>
          <div class="col-sm-6 mb-1">
            <ui-datetimepicker
              :date.prop="endDate"
              :date-format-key="DATE_FORMAT_KEYS.default"
              :range.prop="validEndDateRange"
              :disabled="!startDate"
              :minutes-interval.prop="bookingType === BOOKING_TYPES.unavailability ? 1 : minutesRange"
              :size="SIZES.small"
              :disable-date-coercion="isEditing"
              immediate
              label="End date*"
              required
              data-test-id="booking-form-end-date"
              name="end"
              @datechanged="({ detail }) => validateEndDate(detail)"
            />
          </div>
          <div class="col-12 mb-3 d-flex justify-content-between">
            <div class="emobg-font-weight-semibold">
              <span class="emobg-font-weight-bold mb-3">Duration:</span>
              <span data-test-id="booking-form-duration">
                {{ duration(startDate, endDate) || '0 hours' }}
              </span>
            </div>

            <ui-tooltip
              v-if="hasSkippedRules"
              data-test-id="booking-form-skipped-rules"
              :tooltip="getSkippedRules()"
            >
              <label class="emobg-font-weight-bold emobg-font-small emobg-color-danger mb-3 mr-1">You’ve skipped Booking Rules!</label>
              <ui-icon
                :color="COLORS.danger"
                :icon="ICONS.info"
                :size="ICONS_SIZES.xSmall"
              />
            </ui-tooltip>
          </div>
          <div class="col-12 mb-4">
            <ui-skeleton
              v-if="citiesStatus.LOADING"
              data-test-id="city-skeleton"
            />
            <ui-select
              v-if="citiesStatus.LOADED"
              :value="cityUuid"
              :options.prop="normalizeOptions(citiesData, value => value.uuid, label => label.name)"
              :disabled="isEditing"
              label="City*"
              required
              data-test-id="booking-form-city"
              name="city"
              placeholder="Select"
              class="w-100"
              @selectoption="({ detail }) => cityUuid = detail"
            />
          </div>
          <div class="col-12 mb-4">
            <label
              :class="['d-block emobg-font-weight-bold mb-1',
                       {'disabled': !cityUuid || hasStarted || (!hasStarted && isEditing && !isCarsharing)}
              ]"
              data-test-id="booking-form-location-label"
            >
              {{ isAnotherReturnLocation ? 'Pickup location*' : 'Location*' }}
            </label>
            <ui-skeleton
              v-if="locationsStatus.LOADING"
              data-test-id="location-skeleton"
            />
            <MuiSelect
              v-else
              v-model="locationUuid"
              :disabled="!cityUuid || hasStarted || (!hasStarted && isEditing && !isCarsharing)"
              :options="locations"
              :searchbox="{
                placeholder: locationUuid ? '' : 'Select',
                threshold: 1,
              }"
              option-label="name"
              option-value="uuid"
              required
              data-test-id="booking-form-location"
              class="w-100 SelectorWithIcons"
              placeholder="Select"
              name="location_uuid"
            >
              <template #selected="{ item }">
                <BookingLocationOptionComponent :location="item" />
              </template>
              <template #item="{ item }">
                <BookingLocationOptionComponent :location="item" />
              </template>
            </MuiSelect>
          </div>
          <div
            v-if="isOneWayAllowed && !get(booking, 'isOneWay')"
            class="col-auto mb-4"
          >
            <ui-checkbox
              :checked="isAnotherReturnLocation"
              :disabled="!locationUuid || !isOneWayVehicle"
              caption="Return vehicle to another location"
              data-test-id="booking-form-another_location-checkbox"
              @changevalue="({ detail }) => isAnotherReturnLocation = detail"
            />
          </div>
          <div
            v-if="isAnotherReturnLocation && isBookingTypeOneWayApplicable"
            class="col-12 mb-4"
          >
            <label class="d-block emobg-font-weight-bold mb-1">
              Return location*
            </label>
            <MuiAlgoliaSelect
              v-model="returnLocationUuid"
              :index="ALGOLIA_INDEXES.locations"
              :filters="`cs_operator_uuid:${operatorUuid} AND one_way_allowed: ${isAnotherReturnLocation} AND active: 1`"
              :results-per-page="50"
              :title="(result) => `${result.name} - ${result.city}`"
              data-test-id="booking-form-return-location"
              name="returnLocation"
              no-cache
              path-value="uuid"
              placeholder="Select"
              class="w-100"
            />
          </div>

          <div class="col-12 mb-4">
            <label
              :class="['d-block emobg-font-weight-bold mb-1',
                       {'disabled': isVehicleDisabled}]"
            >
              Vehicle*
            </label>
            <ui-skeleton
              v-if="locationsStatus.LOADING || vehiclesStatus.LOADING"
              data-test-id="location-vehicle-skeleton"
            />
            <MuiSelect
              v-else
              v-model="vehicleUuid"
              :options="vehicles"
              :disabled="isVehicleDisabled"
              option-value="uuid"
              option-label="model"
              placeholder="Select"
              class="w-100 SelectorWithIcons"
              required
              data-test-id="booking-form-vehicle"
              name="vehicle_uuid"
            >
              <template #selected="{ item }">
                <BookingVehicleOptionComponent :vehicle="item" />
              </template>
              <template #item="{ item }">
                <BookingVehicleOptionComponent :vehicle="item" />
              </template>
              <template slot="emptyOptions">
                <div class="d-flex py-2">
                  <ui-icon
                    :icon="ICONS.bold.vehicle"
                    :size="SIZES.medium"
                    class="mr-2"
                  />
                  <span class="emobg-color-ink-light mr-1"> No vehicles available </span>
                  <span class="emobg-font-weight-semibold"> Select other location </span>
                </div>
              </template>
            </MuiSelect>
            <div
              v-if="isChangingCloudBoxxToIBoxx"
              class="d-flex emobg-color-danger align-items-center my-1"
            >
              <ui-icon
                :size="ICONS_SIZES.medium"
                :icon="ICONS.info"
                class="mr-1"
                data-test-id="info-icon"
              />
              Connected Car Platform (CCP device) vehicles shouldn’t be changed to iBoxx vehicles.
            </div>
          </div>
          <div
            v-if="!isEditing && currentProfile.costAllocations && currentProfile.costAllocations.length"
            class="col-12 mb-1"
          >
            <CostAllocationsComponent
              v-model="companyCostAllocations"
              :company-cost-allocations="currentProfile.costAllocations"
              @isValid="value => isCostAllocationsValid = value"
            />
          </div>
          <div class="col-12 mb-4">
            <label class="d-block emobg-font-weight-bold mb-1">
              Comment*
            </label>
            <MuiTextarea
              v-model="comment"
              v-validate="{
                isRequired: true,
                isMinLength: {
                  message: 'We need at least 6 characters',
                  length: 6,
                }
              }"
              :placeholder="placeholder"
              data-test-id="booking-form-comment"
              name="comment"
              rows="4"
            />
            <BookingCommentsComponent
              v-if="isEditing && get(booking, 'comments.length')"
              :comments="get(booking, 'comments')"
            />
          </div>
          <div
            v-if="size(currentPrice) && isCarsharing"
            class="col-12 mb-4"
          >
            <div class="row no-gutters mb-1 mx-0 p-2 emobg-background-color-ground-lightest emobg-border-radius-default">
              <div class="col-4 col-lg-4 col-xl-4 mr-6">
                <label class="d-block emobg-font-weight-bold pb-1">
                  {{ $t(`Carsharing.Planning.Price.${isFreeBooking ? 'new' : 'current'}`) }}
                </label>
                <div
                  v-for="(value, key) in omit(currentPrice, 'currency')"
                  :key="key"
                  class="row mb-2"
                >
                  <div class="col-6">
                    {{ $t(`Carsharing.Planning.Price.${key}`) }}
                  </div>
                  <div class="col-6">
                    {{ formatPreciseCurrency(value, currentPrice.currency) }}
                  </div>
                </div>
              </div>
              <div
                v-if="isEditing && hasPriceChanged"
                class="col-4"
              >
                <label class="d-block emobg-font-weight-bold pb-1">
                  {{ $t('Carsharing.Planning.Price.new') }}
                </label>
                <div
                  v-for="(value, key) in omit(bookingsPrice, 'currency')"
                  :key="key"
                  class="row mb-2"
                >
                  <div class="col-6">
                    {{ $t(`Carsharing.Planning.Price.${key}`) }}
                  </div>
                  <div class="col-6">
                    {{ formatPreciseCurrency(value, bookingsPrice.currency) }}
                  </div>
                </div>
              </div>
              <div
                v-if="get(dedicatedHours, 'extraCost')"
                class="pt-2 emobg-color-ink-light"
              >
                *An additional fee will be included in the monthly invoice regarding booking outside dedicated company hours.
              </div>
            </div>
          </div>
          <div
            v-if="isEditing && isCarsharing && isFreeBooking"
            class="col-12 mb-4"
          >
            <div class="emobg-background-color-ground-lighter emobg-font-small emobg-font-weight-bold p-2">
              This is a free booking
            </div>
          </div>
          <div
            v-if="!isEditing && isCarsharing"
            class="col-12"
          >
            <ui-toggle
              :value="isFreeBooking"
              name="isFreeBooking"
              text="Set as free booking"
              @changevalue="({ detail }) => isFreeBooking = detail"
            />
            <ui-alert
              v-if="isFreeBooking"
              :color="COLORS.primary"
              data-test-id="booking-form-free-alert"
              class="d-block py-2 w-100"
            >
              <p class="emobg-font-weight-bold pb-1">
                {{ $t('Carsharing.Planning.BookingSetup.state_free_booking_reason') }}
              </p>
              <p>{{ $t('Carsharing.Planning.BookingSetup.ensure_manual_invoice') }}</p>
            </ui-alert>
          </div>
          <BookingActionsComponent
            v-if="isEditing"
            :callback="onSuccessCallback"
            :has-started="hasStarted"
            class="col-12"
          />
          <ForceFinish
            v-if="isEditing && !['finished', 'cancelled'].includes(booking.systemStatus)"
            data-test-id="booking-form-force_finish-component"
            :is-booking-started="hasStarted"
            :callback="onSuccessCallback"
            class="col-12"
          />
        </div>
      </div>
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <CancelButton
        class="mr-2"
        data-test-id="booking-form-cancel-button"
        @click="closeModal"
      />

      <ui-dropdown
        v-if="hasPriceChanged && isCarsharing"
        :color="GRAYSCALE.groundLighter"
        :disabled="!isBookingValid || isNotBookable || dedicatedHoursStatus.LOADING"
        arrow
        data-test-id="booking-form-save-price-button"
      >
        <p
          slot="trigger"
          class="pl-3"
        >
          Save
        </p>
        <ui-dropdown-actions
          slot="content"
          :actions.prop="saveActions"
        />
      </ui-dropdown>
      <ui-button
        v-else
        :disabled="!isBookingValid || isNotBookable || dedicatedHoursStatus.LOADING"
        :loading="isButtonLoading"
        data-test-id="booking-form-save-button"
        @clickbutton="isEditing ? editAction() : createBooking()"
      >
        {{ isEditing ? 'Save' : 'Create' }}
      </ui-button>
    </div>
  </GenericModalComponent>
</template>
