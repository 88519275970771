var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ForceFinishComponent mt-2" },
    [
      _c("ui-toggle", {
        attrs: {
          value: _vm.active,
          name: "active",
          disabled: !_vm.isBookingStarted,
          text: `${_vm.toggleAction} advanced finishing action`,
          "data-test-id": "force_finish-toggle",
        },
        on: { changevalue: ({ detail }) => (_vm.active = detail) },
      }),
      _vm.active
        ? _c(
            "div",
            {
              staticClass:
                "emobg-background-color-ground-lightest emobg-border-radius-small mt-2 px-2 pt-2 animated fadeIn",
            },
            [
              _vm._m(0),
              _c(
                "ui-button",
                {
                  staticClass: "mb-3",
                  attrs: {
                    color: _vm.COLORS.danger,
                    loading: _vm.forceFinishStatus.LOADING,
                    disabled: !_vm.isBookingStarted,
                    "data-test-id": "force_finish-button",
                  },
                  on: { clickbutton: _vm.forceFinish },
                },
                [_vm._v(" Force finish ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "emobg-body-1" }, [
      _c("p", { staticClass: "mb-4" }, [
        _c("b", { staticClass: "emobg-color-danger emobg-body-2" }, [
          _vm._v("Force finish"),
        ]),
        _vm._v(" is an action that forces the booking "),
        _c("b", { staticClass: "emobg-body-2" }, [_vm._v(" System Status ")]),
        _vm._v(" to "),
        _c("b", { staticClass: "emobg-body-2" }, [_vm._v(" Finished ")]),
        _vm._v(". "),
      ]),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(
          " This action will finish the booking on the system and attempt to finish it also on the vehicle (but this may not be successful depending on if the vehicle is experiencing connectivity issues). "
        ),
      ]),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(" This action should "),
        _c("b", { staticClass: "emobg-body-2" }, [_vm._v("ONLY")]),
        _vm._v(" be used if below "),
        _c("b", { staticClass: "emobg-body-2" }, [_vm._v("03 steps")]),
        _vm._v(" have already followed: "),
      ]),
      _c("ul", { staticClass: "mb-4" }, [
        _c("li", [
          _c("b", { staticClass: "emobg-body-2" }, [_vm._v("Step 1:")]),
          _vm._v(" The customer tried to terminate their booking properly. "),
        ]),
        _c("li", [
          _c("b", { staticClass: "emobg-body-2" }, [_vm._v("Step 2:")]),
          _vm._v(" The "),
          _c("b", { staticClass: "emobg-color-danger emobg-body-2" }, [
            _vm._v("Terminate as user"),
          ]),
          _vm._v(
            " from the edit booking modal was used but did not end the booking. "
          ),
        ]),
        _c("li", [
          _c("b", { staticClass: "emobg-body-2" }, [_vm._v("Step 3:")]),
          _vm._v(" The "),
          _c("b", { staticClass: "emobg-color-danger emobg-body-2" }, [
            _vm._v("Terminate as admin"),
          ]),
          _vm._v(
            " from the edit booking modal was used but did not end the booking. "
          ),
        ]),
      ]),
      _c("p", { staticClass: "mb-4" }, [
        _vm._v(" If you still need to use this "),
        _c("b", { staticClass: "emobg-color-danger emobg-body-2" }, [
          _vm._v("Force finish"),
        ]),
        _vm._v(" action bear in mind that you will need to: "),
      ]),
      _c("ol", { staticClass: "mb-4" }, [
        _c("li", [
          _vm._v(" 1. Manually "),
          _c("b", { staticClass: "emobg-body-2" }, [
            _vm._v("update the invoice"),
          ]),
          _vm._v(" with the correct time and mileage. "),
        ]),
        _c("li", [
          _vm._v(" 2. Manually "),
          _c("b", { staticClass: "emobg-body-2" }, [
            _vm._v("close the invoice"),
          ]),
          _vm._v(
            " (this will trigger the additional charges manually added to the invoice). "
          ),
        ]),
      ]),
      _c("p", { staticClass: "mb-4" }, [
        _c("b", { staticClass: "emobg-font-weight-semibold" }, [
          _vm._v("Important:"),
        ]),
        _vm._v(
          " Please be aware that force finishing a booking may impact the next booking. Please speak to your manager for assistance. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }