var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        ref: "bookingSetupModal",
        attrs: {
          title: _vm.bookingUuid ? _vm.editTitle : "Create new booking",
          header: { isClosable: true },
          "is-alert-sticky": "",
          "data-test-id": "booking-form-modal",
        },
        on: { "close-modal": _vm.closeModal },
      },
      _vm.$listeners
    ),
    [
      _c(
        "div",
        { attrs: { slot: "alerts" }, slot: "alerts" },
        [
          _vm.isEditing && _vm.isInitialized
            ? _c("BookingActionsFeedback", {
                attrs: {
                  "data-test-id": "booking-form-modal-actions-feedback",
                },
              })
            : _vm._e(),
          _c("BookingFeedback", {
            attrs: { "user-uuid": _vm.userUuid, "is-editing": _vm.isEditing },
          }),
          _c("DedicatedHoursFeedback"),
        ],
        1
      ),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c(
          "div",
          [
            !_vm.isInitialized
              ? _c("ui-loader", {
                  attrs: { relative: "", "data-test-id": "loader" },
                })
              : _vm.getEditBookingError
              ? _c(
                  "div",
                  { attrs: { "data-test-id": "booking-form-modal-errors" } },
                  [_vm._v(" We could not get this booking, see error above ")]
                )
              : _c(
                  "div",
                  { staticClass: "BookingSetup row" },
                  [
                    _vm.booking
                      ? _c(
                          "div",
                          { staticClass: "col-12 emobg-body-2 mb-4 mt-3" },
                          [
                            _c("span", { staticClass: "emobg-color-ink" }, [
                              _vm._v(" Booking "),
                            ]),
                            _c(
                              "PermissionLink",
                              {
                                attrs: {
                                  "link-permissions": [
                                    _vm.CARSHARING_PERMISSIONS
                                      .viewCarsharingBookings,
                                  ],
                                  to: {
                                    name: _vm.carsharingRoutes.bookings.detail
                                      .index,
                                    params: {
                                      bookingUuid: _vm.get(_vm.booking, "uuid"),
                                    },
                                  },
                                  "data-test-id":
                                    "booking-form-modal-booking-link",
                                },
                              },
                              [
                                _vm._v(
                                  " #" +
                                    _vm._s(
                                      _vm.get(_vm.booking, "id") ||
                                        _vm.FALLBACK_MESSAGE.dash
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isAbleToChangeUser
                      ? _c(
                          "div",
                          { staticClass: "col-12 mb-4" },
                          [
                            _c(
                              "label",
                              {
                                class: [
                                  "d-block emobg-font-weight-bold mb-1",
                                  { disabled: !_vm.operatorUuid },
                                ],
                              },
                              [_vm._v(" User* ")]
                            ),
                            _c("MuiAlgoliaSelect", {
                              staticClass: "w-100",
                              attrs: {
                                model: _vm.userUuid,
                                disabled: !_vm.operatorUuid,
                                index: _vm.ALGOLIA_INDEXES.users,
                                filters: `cs_operator_uuid: ${_vm.operatorUuid} OR employee.company.cs_operator_uuid: ${_vm.operatorUuid}`,
                                title: (result) =>
                                  `${result.first_name} ${result.last_name} - ${result.email}`,
                                "data-test-id": "booking-form-user",
                                name: "user",
                                "no-cache": "",
                                "path-value": "uuid",
                                placeholder: "Select",
                              },
                              on: { change: _vm.onChangeUserUuid },
                            }),
                            _c("CirclesFeedbackComponent"),
                            _vm.userUuid && _vm.isEmpty(_vm.currentProfile)
                              ? _c("div", { staticClass: "my-2" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "emobg-font-weight emobg-font-extra-small emobg-color-danger",
                                    },
                                    [
                                      _c("ui-icon", {
                                        attrs: {
                                          icon: _vm.ICONS.info,
                                          size: _vm.ICONS_SIZES.small,
                                          "data-test-id":
                                            "booking-form-user-no-business-profile",
                                        },
                                      }),
                                      _vm._v(
                                        " This user doesn't have business profile "
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.bookingUuid
                          ? _c("BookingInformationComponent", {
                              attrs: {
                                "show-user-link": !_vm.isAbleToChangeUser,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.isEditing
                      ? _c("div", { staticClass: "w-100" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 mb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-weight-bold mb-1",
                                },
                                [_vm._v(" Operator* ")]
                              ),
                              _c("MuiAlgoliaSelect", {
                                staticClass: "w-100",
                                attrs: {
                                  index: _vm.ALGOLIA_INDEXES.csOperators,
                                  title: (result) => result.name,
                                  filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                                  "data-test-id": "booking-form-operator",
                                  name: "operator",
                                  "no-cache": "",
                                  "path-value": "uuid",
                                  placeholder: "Select",
                                },
                                model: {
                                  value: _vm.operatorUuid,
                                  callback: function ($$v) {
                                    _vm.operatorUuid = $$v
                                  },
                                  expression: "operatorUuid",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.operatorConfig.usesPreBooking
                            ? _c(
                                "div",
                                { staticClass: "col-12 mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-block emobg-font-weight-bold mb-1",
                                    },
                                    [
                                      _vm._v(" Pre-booking UUID "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emobg-color-ink-light emobg-line-height-medium",
                                        },
                                        [_vm._v("(optional)")]
                                      ),
                                    ]
                                  ),
                                  _c("MuiAlgoliaSelect", {
                                    staticClass: "w-100",
                                    attrs: {
                                      disabled: !_vm.operatorUuid,
                                      index: _vm.ALGOLIA_INDEXES.prebookings,
                                      filters: `cs_operator_uuid: ${_vm.operatorUuid} AND booking_uuid: null`,
                                      title: (result) => `${result.uuid}`,
                                      "data-test-id":
                                        "booking-form-request-uuid",
                                      name: "requestUuid",
                                      "no-cache": "",
                                      "path-value": "uuid",
                                      placeholder: "Select",
                                    },
                                    on: {
                                      selected: _vm.fillBookingWithPrebooking,
                                    },
                                    model: {
                                      value: _vm.requestUuid,
                                      callback: function ($$v) {
                                        _vm.requestUuid = $$v
                                      },
                                      expression: "requestUuid",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-4" },
                            [
                              _c(
                                "label",
                                {
                                  class: [
                                    "d-block emobg-font-weight-bold mb-1",
                                    { disabled: !_vm.operatorUuid },
                                  ],
                                },
                                [_vm._v(" User* ")]
                              ),
                              _vm.isPrebookingReady
                                ? _c("MuiAlgoliaSelect", {
                                    staticClass: "w-100",
                                    attrs: {
                                      model: _vm.userUuid,
                                      disabled: !_vm.operatorUuid,
                                      index: _vm.ALGOLIA_INDEXES.users,
                                      filters: `cs_operator_uuid: ${_vm.operatorUuid} OR employee.company.cs_operator_uuid: ${_vm.operatorUuid}`,
                                      title: (result) =>
                                        `${result.first_name} ${result.last_name} - ${result.email}`,
                                      "data-test-id":
                                        "booking-form-user-prebooking",
                                      name: "user",
                                      "no-cache": "",
                                      "path-value": "uuid",
                                      placeholder: "Select",
                                    },
                                    on: { change: _vm.onChangeUserUuid },
                                  })
                                : _vm._e(),
                              _c("CirclesFeedbackComponent", {
                                attrs: {
                                  "data-test-id":
                                    "booking_form-circles_feedback",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-4" },
                            [
                              _c("ui-select", {
                                staticClass: "w-100",
                                attrs: {
                                  value: _vm.userProfileUuid,
                                  disabled: !_vm.userUuid,
                                  required: "",
                                  label: "Profile*",
                                  placeholder: "Select",
                                  "data-test-id": "booking-form-profile",
                                  name: "profile",
                                },
                                domProps: {
                                  options: _vm.normalizeOptions(
                                    _vm.userProfiles,
                                    (value) => value.uuid,
                                    (label) => _vm.titleCase(label.type)
                                  ),
                                },
                                on: {
                                  selectoption: ({ detail }) =>
                                    (_vm.userProfileUuid = detail),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mb-4" },
                            [
                              _vm.bookingTypesStatus.LOADING
                                ? _c("ui-skeleton", {
                                    attrs: {
                                      "data-test-id": "booking_type-skeleton",
                                    },
                                  })
                                : _c("ui-select", {
                                    staticClass: "w-100",
                                    attrs: {
                                      value: _vm.bookingType,
                                      label: "Booking type*",
                                      placeholder: "Select",
                                      required: "",
                                      "data-test-id": "booking-form-type",
                                      name: "booking_type",
                                    },
                                    domProps: {
                                      options: _vm.normalizeOptions(
                                        _vm.bookingTypes,
                                        (value) => value,
                                        (label) => _vm.titleCase(label)
                                      ),
                                    },
                                    on: {
                                      selectoption: ({ detail }) =>
                                        (_vm.bookingType = detail),
                                    },
                                  }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.isCarsharing
                      ? _c(
                          "div",
                          { staticClass: "col-12 mb-4" },
                          [
                            _c("ui-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: _vm.insuranceUuid,
                                disabled:
                                  _vm.hasStarted || !_vm.userProfileUuid,
                                placeholder: "Select",
                                label: "Insurance type*",
                                required: "",
                                "data-test-id": "booking-form-insurance",
                                name: "insurance_type",
                              },
                              domProps: {
                                options: _vm.normalizeOptions(
                                  _vm.insurances,
                                  (value) => value.uuid,
                                  (label) => label.name
                                ),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.insuranceUuid = detail),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bookingType === _vm.BOOKING_TYPES.intervention
                      ? _c(
                          "div",
                          { staticClass: "col-12 mb-4" },
                          [
                            _c(
                              "label",
                              {
                                class: [
                                  "d-block emobg-font-weight-bold mb-1",
                                  {
                                    disabled:
                                      _vm.hasStarted ||
                                      _vm.get(_vm.booking, "isAutomatic"),
                                  },
                                ],
                              },
                              [_vm._v(" Intervention type* ")]
                            ),
                            _c("MuiAlgoliaSelect", {
                              staticClass: "m-0 w-100 pb-2",
                              attrs: {
                                disabled:
                                  _vm.hasStarted ||
                                  _vm.get(_vm.booking, "isAutomatic"),
                                index: _vm.ALGOLIA_INDEXES.carsharingTags,
                                "results-per-page": 50,
                                title: _vm.interventionTitle,
                                filters: "group:interventions",
                                "data-test-id": "booking-form-intervention",
                                name: "intervention",
                                "path-value": "internal_name",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.interventionType,
                                callback: function ($$v) {
                                  _vm.interventionType = $$v
                                },
                                expression: "interventionType",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 mb-1" },
                      [
                        _c("ui-datetimepicker", {
                          attrs: {
                            "date-format-key": _vm.DATE_FORMAT_KEYS.default,
                            disabled: _vm.hasStarted,
                            size: _vm.SIZES.small,
                            "disable-date-coercion": _vm.isEditing,
                            immediate: "",
                            label: "Start date*",
                            required: "",
                            "data-test-id": "booking-form-start-date",
                            name: "start",
                          },
                          domProps: {
                            date: _vm.startDate,
                            range: _vm.validDateRange,
                            minutesInterval:
                              _vm.bookingType ===
                              _vm.BOOKING_TYPES.unavailability
                                ? 1
                                : _vm.minutesRange,
                          },
                          on: {
                            datechanged: ({ detail }) =>
                              _vm.validateStartDate(detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 mb-1" },
                      [
                        _c("ui-datetimepicker", {
                          attrs: {
                            "date-format-key": _vm.DATE_FORMAT_KEYS.default,
                            disabled: !_vm.startDate,
                            size: _vm.SIZES.small,
                            "disable-date-coercion": _vm.isEditing,
                            immediate: "",
                            label: "End date*",
                            required: "",
                            "data-test-id": "booking-form-end-date",
                            name: "end",
                          },
                          domProps: {
                            date: _vm.endDate,
                            range: _vm.validEndDateRange,
                            minutesInterval:
                              _vm.bookingType ===
                              _vm.BOOKING_TYPES.unavailability
                                ? 1
                                : _vm.minutesRange,
                          },
                          on: {
                            datechanged: ({ detail }) =>
                              _vm.validateEndDate(detail),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 mb-3 d-flex justify-content-between",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "emobg-font-weight-semibold" },
                          [
                            _c(
                              "span",
                              { staticClass: "emobg-font-weight-bold mb-3" },
                              [_vm._v("Duration:")]
                            ),
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-test-id": "booking-form-duration",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.duration(
                                        _vm.startDate,
                                        _vm.endDate
                                      ) || "0 hours"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm.hasSkippedRules
                          ? _c(
                              "ui-tooltip",
                              {
                                attrs: {
                                  "data-test-id": "booking-form-skipped-rules",
                                  tooltip: _vm.getSkippedRules(),
                                },
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "emobg-font-weight-bold emobg-font-small emobg-color-danger mb-3 mr-1",
                                  },
                                  [_vm._v("You’ve skipped Booking Rules!")]
                                ),
                                _c("ui-icon", {
                                  attrs: {
                                    color: _vm.COLORS.danger,
                                    icon: _vm.ICONS.info,
                                    size: _vm.ICONS_SIZES.xSmall,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _vm.citiesStatus.LOADING
                          ? _c("ui-skeleton", {
                              attrs: { "data-test-id": "city-skeleton" },
                            })
                          : _vm._e(),
                        _vm.citiesStatus.LOADED
                          ? _c("ui-select", {
                              staticClass: "w-100",
                              attrs: {
                                value: _vm.cityUuid,
                                disabled: _vm.isEditing,
                                label: "City*",
                                required: "",
                                "data-test-id": "booking-form-city",
                                name: "city",
                                placeholder: "Select",
                              },
                              domProps: {
                                options: _vm.normalizeOptions(
                                  _vm.citiesData,
                                  (value) => value.uuid,
                                  (label) => label.name
                                ),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.cityUuid = detail),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _c(
                          "label",
                          {
                            class: [
                              "d-block emobg-font-weight-bold mb-1",
                              {
                                disabled:
                                  !_vm.cityUuid ||
                                  _vm.hasStarted ||
                                  (!_vm.hasStarted &&
                                    _vm.isEditing &&
                                    !_vm.isCarsharing),
                              },
                            ],
                            attrs: {
                              "data-test-id": "booking-form-location-label",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isAnotherReturnLocation
                                    ? "Pickup location*"
                                    : "Location*"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _vm.locationsStatus.LOADING
                          ? _c("ui-skeleton", {
                              attrs: { "data-test-id": "location-skeleton" },
                            })
                          : _c("MuiSelect", {
                              staticClass: "w-100 SelectorWithIcons",
                              attrs: {
                                disabled:
                                  !_vm.cityUuid ||
                                  _vm.hasStarted ||
                                  (!_vm.hasStarted &&
                                    _vm.isEditing &&
                                    !_vm.isCarsharing),
                                options: _vm.locations,
                                searchbox: {
                                  placeholder: _vm.locationUuid ? "" : "Select",
                                  threshold: 1,
                                },
                                "option-label": "name",
                                "option-value": "uuid",
                                required: "",
                                "data-test-id": "booking-form-location",
                                placeholder: "Select",
                                name: "location_uuid",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "selected",
                                  fn: function ({ item }) {
                                    return [
                                      _c("BookingLocationOptionComponent", {
                                        attrs: { location: item },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c("BookingLocationOptionComponent", {
                                        attrs: { location: item },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.locationUuid,
                                callback: function ($$v) {
                                  _vm.locationUuid = $$v
                                },
                                expression: "locationUuid",
                              },
                            }),
                      ],
                      1
                    ),
                    _vm.isOneWayAllowed && !_vm.get(_vm.booking, "isOneWay")
                      ? _c(
                          "div",
                          { staticClass: "col-auto mb-4" },
                          [
                            _c("ui-checkbox", {
                              attrs: {
                                checked: _vm.isAnotherReturnLocation,
                                disabled:
                                  !_vm.locationUuid || !_vm.isOneWayVehicle,
                                caption: "Return vehicle to another location",
                                "data-test-id":
                                  "booking-form-another_location-checkbox",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.isAnotherReturnLocation = detail),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isAnotherReturnLocation &&
                    _vm.isBookingTypeOneWayApplicable
                      ? _c(
                          "div",
                          { staticClass: "col-12 mb-4" },
                          [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-bold mb-1",
                              },
                              [_vm._v(" Return location* ")]
                            ),
                            _c("MuiAlgoliaSelect", {
                              staticClass: "w-100",
                              attrs: {
                                index: _vm.ALGOLIA_INDEXES.locations,
                                filters: `cs_operator_uuid:${_vm.operatorUuid} AND one_way_allowed: ${_vm.isAnotherReturnLocation} AND active: 1`,
                                "results-per-page": 50,
                                title: (result) =>
                                  `${result.name} - ${result.city}`,
                                "data-test-id": "booking-form-return-location",
                                name: "returnLocation",
                                "no-cache": "",
                                "path-value": "uuid",
                                placeholder: "Select",
                              },
                              model: {
                                value: _vm.returnLocationUuid,
                                callback: function ($$v) {
                                  _vm.returnLocationUuid = $$v
                                },
                                expression: "returnLocationUuid",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _c(
                          "label",
                          {
                            class: [
                              "d-block emobg-font-weight-bold mb-1",
                              { disabled: _vm.isVehicleDisabled },
                            ],
                          },
                          [_vm._v(" Vehicle* ")]
                        ),
                        _vm.locationsStatus.LOADING ||
                        _vm.vehiclesStatus.LOADING
                          ? _c("ui-skeleton", {
                              attrs: {
                                "data-test-id": "location-vehicle-skeleton",
                              },
                            })
                          : _c(
                              "MuiSelect",
                              {
                                staticClass: "w-100 SelectorWithIcons",
                                attrs: {
                                  options: _vm.vehicles,
                                  disabled: _vm.isVehicleDisabled,
                                  "option-value": "uuid",
                                  "option-label": "model",
                                  placeholder: "Select",
                                  required: "",
                                  "data-test-id": "booking-form-vehicle",
                                  name: "vehicle_uuid",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selected",
                                    fn: function ({ item }) {
                                      return [
                                        _c("BookingVehicleOptionComponent", {
                                          attrs: { vehicle: item },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _c("BookingVehicleOptionComponent", {
                                          attrs: { vehicle: item },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.vehicleUuid,
                                  callback: function ($$v) {
                                    _vm.vehicleUuid = $$v
                                  },
                                  expression: "vehicleUuid",
                                },
                              },
                              [
                                _c("template", { slot: "emptyOptions" }, [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex py-2" },
                                    [
                                      _c("ui-icon", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          icon: _vm.ICONS.bold.vehicle,
                                          size: _vm.SIZES.medium,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emobg-color-ink-light mr-1",
                                        },
                                        [_vm._v(" No vehicles available ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emobg-font-weight-semibold",
                                        },
                                        [_vm._v(" Select other location ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                        _vm.isChangingCloudBoxxToIBoxx
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex emobg-color-danger align-items-center my-1",
                              },
                              [
                                _c("ui-icon", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    size: _vm.ICONS_SIZES.medium,
                                    icon: _vm.ICONS.info,
                                    "data-test-id": "info-icon",
                                  },
                                }),
                                _vm._v(
                                  " Connected Car Platform (CCP device) vehicles shouldn’t be changed to iBoxx vehicles. "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.isEditing &&
                    _vm.currentProfile.costAllocations &&
                    _vm.currentProfile.costAllocations.length
                      ? _c(
                          "div",
                          { staticClass: "col-12 mb-1" },
                          [
                            _c("CostAllocationsComponent", {
                              attrs: {
                                "company-cost-allocations":
                                  _vm.currentProfile.costAllocations,
                              },
                              on: {
                                isValid: (value) =>
                                  (_vm.isCostAllocationsValid = value),
                              },
                              model: {
                                value: _vm.companyCostAllocations,
                                callback: function ($$v) {
                                  _vm.companyCostAllocations = $$v
                                },
                                expression: "companyCostAllocations",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-12 mb-4" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "d-block emobg-font-weight-bold mb-1",
                          },
                          [_vm._v(" Comment* ")]
                        ),
                        _c("MuiTextarea", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                                isMinLength: {
                                  message: "We need at least 6 characters",
                                  length: 6,
                                },
                              },
                              expression:
                                "{\n              isRequired: true,\n              isMinLength: {\n                message: 'We need at least 6 characters',\n                length: 6,\n              }\n            }",
                            },
                          ],
                          attrs: {
                            placeholder: _vm.placeholder,
                            "data-test-id": "booking-form-comment",
                            name: "comment",
                            rows: "4",
                          },
                          model: {
                            value: _vm.comment,
                            callback: function ($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment",
                          },
                        }),
                        _vm.isEditing && _vm.get(_vm.booking, "comments.length")
                          ? _c("BookingCommentsComponent", {
                              attrs: {
                                comments: _vm.get(_vm.booking, "comments"),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.size(_vm.currentPrice) && _vm.isCarsharing
                      ? _c("div", { staticClass: "col-12 mb-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row no-gutters mb-1 mx-0 p-2 emobg-background-color-ground-lightest emobg-border-radius-default",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-4 col-lg-4 col-xl-4 mr-6" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-block emobg-font-weight-bold pb-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              `Carsharing.Planning.Price.${
                                                _vm.isFreeBooking
                                                  ? "new"
                                                  : "current"
                                              }`
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.omit(_vm.currentPrice, "currency"),
                                    function (value, key) {
                                      return _c(
                                        "div",
                                        { key: key, staticClass: "row mb-2" },
                                        [
                                          _c("div", { staticClass: "col-6" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    `Carsharing.Planning.Price.${key}`
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("div", { staticClass: "col-6" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatPreciseCurrency(
                                                    value,
                                                    _vm.currentPrice.currency
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm.isEditing && _vm.hasPriceChanged
                                ? _c(
                                    "div",
                                    { staticClass: "col-4" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "d-block emobg-font-weight-bold pb-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "Carsharing.Planning.Price.new"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.omit(_vm.bookingsPrice, "currency"),
                                        function (value, key) {
                                          return _c(
                                            "div",
                                            {
                                              key: key,
                                              staticClass: "row mb-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          `Carsharing.Planning.Price.${key}`
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.formatPreciseCurrency(
                                                          value,
                                                          _vm.bookingsPrice
                                                            .currency
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm.get(_vm.dedicatedHours, "extraCost")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pt-2 emobg-color-ink-light",
                                    },
                                    [
                                      _vm._v(
                                        " *An additional fee will be included in the monthly invoice regarding booking outside dedicated company hours. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.isEditing && _vm.isCarsharing && _vm.isFreeBooking
                      ? _c("div", { staticClass: "col-12 mb-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "emobg-background-color-ground-lighter emobg-font-small emobg-font-weight-bold p-2",
                            },
                            [_vm._v(" This is a free booking ")]
                          ),
                        ])
                      : _vm._e(),
                    !_vm.isEditing && _vm.isCarsharing
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("ui-toggle", {
                              attrs: {
                                value: _vm.isFreeBooking,
                                name: "isFreeBooking",
                                text: "Set as free booking",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_vm.isFreeBooking = detail),
                              },
                            }),
                            _vm.isFreeBooking
                              ? _c(
                                  "ui-alert",
                                  {
                                    staticClass: "d-block py-2 w-100",
                                    attrs: {
                                      color: _vm.COLORS.primary,
                                      "data-test-id": "booking-form-free-alert",
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "emobg-font-weight-bold pb-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "Carsharing.Planning.BookingSetup.state_free_booking_reason"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Carsharing.Planning.BookingSetup.ensure_manual_invoice"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isEditing
                      ? _c("BookingActionsComponent", {
                          staticClass: "col-12",
                          attrs: {
                            callback: _vm.onSuccessCallback,
                            "has-started": _vm.hasStarted,
                          },
                        })
                      : _vm._e(),
                    _vm.isEditing &&
                    !["finished", "cancelled"].includes(
                      _vm.booking.systemStatus
                    )
                      ? _c("ForceFinish", {
                          staticClass: "col-12",
                          attrs: {
                            "data-test-id":
                              "booking-form-force_finish-component",
                            "is-booking-started": _vm.hasStarted,
                            callback: _vm.onSuccessCallback,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            staticClass: "mr-2",
            attrs: { "data-test-id": "booking-form-cancel-button" },
            on: { click: _vm.closeModal },
          }),
          _vm.hasPriceChanged && _vm.isCarsharing
            ? _c(
                "ui-dropdown",
                {
                  attrs: {
                    color: _vm.GRAYSCALE.groundLighter,
                    disabled:
                      !_vm.isBookingValid ||
                      _vm.isNotBookable ||
                      _vm.dedicatedHoursStatus.LOADING,
                    arrow: "",
                    "data-test-id": "booking-form-save-price-button",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "pl-3",
                      attrs: { slot: "trigger" },
                      slot: "trigger",
                    },
                    [_vm._v(" Save ")]
                  ),
                  _c("ui-dropdown-actions", {
                    attrs: { slot: "content" },
                    domProps: { actions: _vm.saveActions },
                    slot: "content",
                  }),
                ],
                1
              )
            : _c(
                "ui-button",
                {
                  attrs: {
                    disabled:
                      !_vm.isBookingValid ||
                      _vm.isNotBookable ||
                      _vm.dedicatedHoursStatus.LOADING,
                    loading: _vm.isButtonLoading,
                    "data-test-id": "booking-form-save-button",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _vm.isEditing ? _vm.editAction() : _vm.createBooking()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.isEditing ? "Save" : "Create") + " ")]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }